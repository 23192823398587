// Generated by Framer (cfe29ee)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {HX4ebnslM: {hover: true}};

const cycleOrder = ["HX4ebnslM"];

const variantClassNames = {HX4ebnslM: "framer-v-78yh8i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "HX4ebnslM", title: XhUIXh9er = "Services", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "HX4ebnslM", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-A8QtI", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-78yh8i", className)} data-framer-name={"Defaul"} layoutDependency={layoutDependency} layoutId={"HX4ebnslM"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"HX4ebnslM-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p>Services</motion.p></React.Fragment>} className={"framer-y4rmpg"} layoutDependency={layoutDependency} layoutId={"F_1ahBc6k"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={XhUIXh9er} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-A8QtI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-A8QtI .framer-twm2vl { display: block; }", ".framer-A8QtI .framer-78yh8i { height: 20px; overflow: visible; position: relative; width: 65px; }", ".framer-A8QtI .framer-y4rmpg { flex: none; height: auto; left: 49%; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-A8QtI .framer-v-78yh8i .framer-78yh8i { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 65
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"cB94ysT5l":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XhUIXh9er":"title"}
 */
const FramerMjLj2mnA0: React.ComponentType<Props> = withCSS(Component, css, "framer-A8QtI") as typeof Component;
export default FramerMjLj2mnA0;

FramerMjLj2mnA0.displayName = "Menu list";

FramerMjLj2mnA0.defaultProps = {height: 20, width: 65};

addPropertyControls(FramerMjLj2mnA0, {XhUIXh9er: {defaultValue: "Services", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerMjLj2mnA0, [])